<template>
    <div>
        <v-snackbar top v-model="snackbar" :color="color" :timeout="5000">
            <v-row justify="space-between">
                <p class="mb-0 mt-1">{{text}}</p>
                <v-btn icon color="white" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
        <div :style="`background-image: url('${$store.state.catImg}')`" class="cover" id="cover">
          <div class="cover-header">
            <p class="text-center cover-header-text">Check out our {{$route.params.catName}} products</p>
          </div>
        </div>
        <v-progress-linear
        v-if="pageLoading && products.length === 0"
        color="deep-purple accent-4"
        indeterminate
        height="6"
        ></v-progress-linear>
        <v-container v-if="!pageLoading && products.length > 0">
            <v-row v-scrollanimation align="center" class="my-10">
              <v-col cols="12">
                <div>
                  <p class="darkPurple--text headTitle text-capetalize">{{$route.params.catName}} بأفضل سعر</p>
                </div>
              </v-col>
            </v-row>
            <v-row class="my-10">
                <v-col v-scrollanimation cols="12" sm="6" md="4" lg="3" v-for="product in products" :key="product.id">
                  <v-tooltip top color="purple">
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                      v-bind="attrs"
                      v-on="on"
                      class="mx-auto my-5 elevation-4"
                      color="purple"
                      tile
                      @click="$router.push({ name: 'details' }); setProduct(product)"
                      min-height="320"
                      >
                        <img class="ribbon-img" v-if="product.specialOffer" src="../assets/ribbon.png" alt="ribbon">
                        <v-hover close-delay="25" v-slot="{ hover }">
                          <div class="img-cont">
                            <img :class="hover ? 'img-hover' : 'img'" :src="product.photoPath" width="100%">
                          </div>
                        </v-hover>
                        <v-card-text
                        class="pt-6"
                        style="position: relative;"
                        >
                            <h3 class="text-h5 white--text mb-2">
                            {{product.name}}
                            </h3>
                            <div class="font-weight-bold desc title white--text" v-if="product.price && product.price > 0">EGP{{product.price}}</div>
                        </v-card-text>
                        <v-hover close-delay="25" v-slot="{ hover }">
                        <v-card-actions>
                          <div :class="hover ? 'font-weight-light desc title white purple--text mb-2' : 'font-weight-medium desc title white--text mb-2'">
                            اعرض التفاصيل <v-icon :color="hover? 'purple' : 'white'">mdi-chevron-left</v-icon>
                          </div>
                        </v-card-actions>
                        </v-hover>
                      </v-card>
                    </template>
                    <span>{{product.name}}</span>
                  </v-tooltip>
                </v-col>
            </v-row>
        </v-container>
        <p v-if="!pageLoading && products.length === 0" class="text-center mt-5">لم نتمكن من الوصول للخادم</p>
    </div>
</template>

<script>
import { products } from '../links'

export default {
  data () {
    return {
      products: [],
      pageLoading: true,
      snackbar: false,
      categoryImg: localStorage.getItem('catImg'),
      text: '',
      color: ''
    }
  },
  computed: {
    route () {
      return this.$route.params
    },
    catImage () {
      return localStorage.getItem('catImg')
    }
  },
  watch: {
    route (newVal) {
      this.getProducts()
    },
    catImage (newVal) {
      console.log('here')
      document.getElementById('cover').style.backgroundImage = `url('${newVal}')`
    }
  },
  methods: {
    setProduct (product) {
      localStorage.setItem('product', JSON.stringify(product))
    },
    getProducts () {
      this.products = []
      this.$http.get(products, { timeout: 15000 }).then(response => {
        if (response.status === 200) {
          const allProducts = response.body
          allProducts.forEach(item => {
            if (item.category.id === Number(this.$route.params.catId)) {
              this.products.push(item)
            }
          })
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = response.statusText
        }
        this.pageLoading = false
      }, () => {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Something went wrong'
        this.pageLoading = false
      })
    }
  },
  mounted () {
    // document.getElementById('cover').style.backgroundImage = `url('${localStorage.getItem('catImg')}')`
  },
  created () {
    if (this.$route.params.catId) {
      this.getProducts()
    } else this.pageLoading = false
  }
}
</script>

<style lang="scss">
    .img-cont {
        height: 150px;
        width: auto;
        overflow: hidden;
    }
    .img {
        transition: all 0.5s ease-in-out;
        transform: scale(1);
    }
    .img-hover {
        transition: all 0.5s ease-in-out;
        transform: scale(1.2);
    }
    .cover {
      height: 300px;
      width: 100%;
      background-position: center;
      background-size: cover;
      box-shadow: 1px 1px 3px #ccc;
    }
    .cover-header {
      background: linear-gradient(90deg, rgba(2,0,36,0.7651435574229692) 0%, rgba(52,152,219,0.7931547619047619) 100%);
      width: 100%;
      height: 300px;
      position: relative;
    }
    .cover-header-text {
      font-size: 52px;
      font-weight: 700;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @media screen and (max-width: 850px) {
      .cover-header-text {
        font-size: 40px;
      }
    }
    @media screen and (max-width: 450px) {
      .cover-header-text {
        font-size: 30px;
      }
      .img-cont {
        height: 250px;
      }
    }
    @media screen and (max-width: 599px) {
      .img-cont {
        height: 250px;
      }
    }
    /* ribbon */
    .ribbon-img {
      position: absolute;
      width: 50%;
      transform: translateY(-27%);
      z-index: 999;
    }
</style>
