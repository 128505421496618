<template>
  <div>
    <slider></slider>
    <v-container class="my-15">
      <p v-scrollanimation class="text-center header-text">كيف نعمل؟</p>
      <v-row justify="center" class="my-10">
        <v-col v-scrollanimation cols="12" sm="6" md="3">
          <div class="d-flex">
            <v-hover v-slot="{ hover }">
              <v-btn
              fab
              :outlined="!hover"
              color="purple"
              small
              >
                <span :class="hover ? 'white--text body-1' : 'purple--text body-1'">&#x0661;</span>
              </v-btn>
            </v-hover>
            <div class="mr-5">
              <p class="body-text gray--text"> استقبال طلب العميل ودراسته جيداً لتحديد الاحتياج والحل المناسب له.</p>
            </div>
          </div>
        </v-col>
        <v-col v-scrollanimation cols="12" sm="6" md="3">
          <div class="d-flex">
            <v-hover v-slot="{ hover }">
              <v-btn
              fab
              :outlined="!hover"
              color="purple"
              small
              >
                <span :class="hover ? 'white--text body-1' : 'purple--text body-1'">&#x0662;</span>
              </v-btn>
            </v-hover>
            <div class="mr-5">
              <p class="body-text gray--text"> مناقشة كافة الحلول الممكنة مع العميل لتحديد الحل الأنسب و تقديم كافة الحلول المناسبة بأفضل جودة وأفضل سعر</p>
            </div>
          </div>
        </v-col>
        <v-col v-scrollanimation cols="12" sm="6" md="3">
          <div class="d-flex">
            <v-hover v-slot="{ hover }">
              <v-btn
              fab
              :outlined="!hover"
              color="purple"
              small
              >
                <span :class="hover ? 'white--text body-1' : 'purple--text body-1'">&#x0663;</span>
              </v-btn>
            </v-hover>
            <div class="mr-5">
              <p class="body-text gray--text"> تنفيذ الحل المناسب المُتفق عليه بيننا وبين العميل وضمان جودة التركيب والتنفيذ بأفضل سعر وأقل وقت ممكن.</p>
            </div>
          </div>
        </v-col>
        <v-col v-scrollanimation cols="12" sm="6" md="3">
          <div class="d-flex">
            <v-hover v-slot="{ hover }">
              <v-btn
              fab
              :outlined="!hover"
              color="purple"
              small
              >
                <span :class="hover ? 'white--text body-1' : 'purple--text body-1'">&#x0664;</span>
              </v-btn>
            </v-hover>
            <div class="mr-5">
              <p class="body-text gray--text"> المتابعة الدورية مع العميل لضمان سلامة المنتج والمساعدة في حل اي عطل سريع أو مشكلة فنية سريعة.</p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <contact class="mt-15"></contact>
    </v-container>
  </div>
</template>

<script>
import slider from '../components/sliders.vue'
import contact from '../components/contact.vue'

export default {
  components: {
    slider,
    contact
  }
}
</script>
