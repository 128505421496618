import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import about from '../views/about.vue'
import contact from '../views/contact.vue'
import categories from '../views/categories.vue'
import products from '../views/products.vue'
import details from '../views/details.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/categories',
    name: 'categories',
    component: categories
  },
  {
    path: '/products/:catName/:catId',
    name: 'products',
    component: products
  },
  {
    path: '/product',
    name: 'details',
    component: details
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
