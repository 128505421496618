<template>
    <div style="height: auto;">
        <v-container style="direction: ltr">
            <v-row  style="min-height: 90vh;" v-scrollanimation justify="space-between" align="center">
                <v-col cols="12" md="4">
                    <div class="item">
                        <div class="contact-item px-auto">
                            <div>
                                <v-icon color="purple" class="mt-2" left>mdi-phone</v-icon>
                            </div>
                            <a class="text-caption text-sm-h6 font-weight-light mt-1" href="tel:01128976071">01128976071 </a>
                        </div>
                        <div class="contact-item px-auto">
                            <div>
                                <v-icon color="purple" class="mt-2" left>mdi-phone</v-icon>
                            </div>
                            <a class="text-caption text-sm-h6 font-weight-light mt-1" href="tel:01005310938"> 01005310938</a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="contact-item px-auto">
                            <div>
                                <v-icon color="purple" class="mt-2" left>mdi-fax</v-icon>
                            </div>
                            <a class="text-body-1 text-sm-h6 font-weight-light mt-1" href="fax:33352429">33352429</a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="contact-item px-auto">
                            <div>
                                <v-icon color="purple" class="mt-2" left>mdi-email</v-icon>
                            </div>
                            <a class="text-body-1 text-sm-h6 font-weight-light mt-1" href = "mailto: Hanan@h-technology.co">Hanan@h-technology.co</a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="contact-item px-auto">
                            <div>
                                <v-icon color="purple" class="mt-2" left>mdi-map-marker</v-icon>
                            </div>
                            <a href="https://goo.gl/maps/MCPkQyCTYgALNigUA" target="_blank" class="text-body-1 text-sm-h6 font-weight-light mt-1">28-26 Al Dokki St, Ad Doqi, Dokki, Giza Governorate</a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="contact-item px-auto">
                            <div>
                                <v-icon color="purple" class="mt-2" left>mdi-whatsapp</v-icon>
                            </div>
                            <a href="https://wa.me/201128976071" class="text-body-1 text-sm-h6 font-weight-light mt-1" target="_blank">01128976071</a>
                        </div>
                    </div>
                </v-col>
                <v-col style="direction: rtl;" cols="12" md="8" :class="`${$vuetify.breakpoint.smAndDown ? 'order-first' : ''}`">
                    <contact></contact>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import contact from '../components/contact.vue'

export default {
  components: {
    contact
  }
}
</script>

<style>
    .contact-item {
        display: flex;
        /* margin-right: 30px;
        text-align: center; */
        margin-top: 30px;
    }
    .icon-cont {
        width: 35px;
        height: 35px;
        background-color: #4E4CA0;
        border-radius: 50%;
        margin-right: 5px;
        text-align: center;
        padding-top: 5px;
    }
    @media screen and (max-width: 960px) {
        .item {
            display: inline-flex;
            width: 50%;
        }
    }
    @media screen and (max-width: 475px) {
        .item {
            display: block;
            width: 50%;
        }
    }
</style>
