<template>
    <div>
        <v-snackbar top v-model="snackbar" :color="color" :timeout="5000">
            <v-row justify="space-between">
                <p class="mb-0 mt-1">{{text}}</p>
                <v-btn icon color="white" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
        <p v-scrollanimation :class="`header-text ${$route.name === 'Home' || $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right'}`">اترك لنا رسالة</p>
        <p v-scrollanimation :class="`body-text grey--text mb-10 ${$route.name === 'Home' || $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right'}`">دعنا نعرف كيف يمكننا مساعدتك بالتواصل معنا </p>
        <v-form v-model="valid" ref="form">
            <v-row v-scrollanimation :justify="`${$route.name === 'Home' || $vuetify.breakpoint.smAndDown ? 'center' : 'start'}`">
                <v-col cols="12" sm="6" :md="`${$route.name === 'Home' ? '4' : '6'}`">
                    <v-text-field v-model="name" :rules="[v => (!!v && v.length > 2) || 'يجب ملء هذا الحقل', v => /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*((-|\s)*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_])*$/.test(v) || 'يجب أن يكون الاسم بالحروف فقط']" color="lightBlue" outlined tile placeholder="الاسم"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :md="`${$route.name === 'Home' ? '4' : '6'}`">
                    <v-text-field v-model="email" :rules="[v => !!v || 'يجب ملء هذا الحقل', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'بالرجاء إدخال بريد صالح']" color="lightBlue" outlined tile placeholder="البريد الإلكتروني"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-scrollanimation :justify="`${$route.name === 'Home' ? 'center' : 'start'}`">
                <v-col cols="12" sm="6" :md="`${$route.name === 'Home' ? '4' : '6'}`">
                    <v-text-field class="inputNum" :rules="phoneRules" v-model="phone" type="number" color="lightBlue" outlined tile placeholder="الهاتف"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :md="`${$route.name === 'Home' ? '4' : '6'}`">
                    <v-text-field v-model="subject" :rules="[v => (!!v && v.length > 2) || 'يجب ملء هذا الحقل']" color="lightBlue" outlined tile placeholder="العنوان"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-scrollanimation :justify="`${$route.name === 'Home' ? 'center' : 'start'}`">
                <v-col cols="12" :md="`${$route.name === 'Home' ? '8' : '12'}`">
                    <v-textarea placeholder="الرسالة" :rules="[v => (!!v && v.length > 2) || 'يجب ملء هذا الحقل']" v-model="message" color="lightBlue" outlined tile></v-textarea>
                </v-col>
            </v-row>
            <v-row v-scrollanimation :justify="`${$route.name === 'Home' || $vuetify.breakpoint.smAndDown ? 'center' : 'end'}`">
                <v-btn :loading="loading" large class="mt-5 pa-5 elevation-0" @click="submit" color="purple white--text">ارسال الرسالة</v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { contact } from '../links'
export default {
  data () {
    return {
      name: '',
      email: '',
      phone: '',
      phoneRules: [
        v => (!!v && v.length === 11) || 'يجب أن يكون 11 رقم',
        v => v.charAt(0) === '0' || 'بالرجاء إدخال هاتف صالح',
        v => v.charAt(1) === '1' || 'بالرجاء إدخال هاتف صالح',
        v => v.charAt(2) === '5' || v.charAt(2) === '0' || v.charAt(2) === '1' || v.charAt(2) === '2' || 'بالرجاء إدخال هاتف صالح',
        v => (!isNaN(v)) || 'يجب أن يكون الهاتف أرقام فقط'
      ],
      subject: '',
      message: '',
      valid: true,
      snackbar: false,
      color: '',
      text: '',
      loading: false
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        const body = {
          clientName: this.name,
          clientEmail: this.email,
          clientPhoneNumber: this.phone,
          subject: this.suject,
          message: this.message
        }
        this.loading = true
        this.$http.post(contact, body, { timeout: 20000 }).then(response => {
          this.loading = false
          if (response.status === 200) {
            this.snackbar = true
            this.color = 'success'
            this.text = 'تم إرسال الرسالة بنجاح'
            this.$refs.form.reset()
          } else {
            this.snackbar = true
            this.color = 'error'
            this.text = response.statusText
          }
        }, () => {
          this.snackbar = true
          this.color = 'error'
          this.text = 'للأسف حدث خطأ ما, من فضلك حاول بعد قليل'
          this.loading = false
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'من فضلك املأ كل الخانات'
      }
    }
  }
}
</script>

<style>
    .inputNum  input[type='number'] {
        -moz-appearance:textfield;
    }
    .inputNum input::-webkit-outer-spin-button,
    .inputNum input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
</style>
