<template>
    <div>
        <slider></slider>
        <v-container class="my-15">
            <p v-scrollanimation class="header-text text-center">من نحن ؟</p>
            <p v-scrollanimation class="subtitle gray--text text-center">شركة تهتم بتوفير الحلول الذكية لكل من يبحثون عن الوسائل الحديثة لتطوير أعمالهم وتقديمها بطريقة ذكية، وذلك من خلال توفير حلول وأدوات ذكية تساهم في تسهيل عرض الأفكار وتبسيط عملية متابعة الأعمال بأعلى جودة ممكنة وبالتعاون مع كبرى الشركات العالمية لتصنيع أدوات تطوير التعليم.</p>
            <v-row class="my-5">
                <v-col cols="12" sm="6">
                    <v-card height="300" class="text-center pa-5">
                        <v-icon color="purple" large>mdi-diamond-stone</v-icon>
                        <v-card-title><p class="mx-auto my-5">قيمنا</p></v-card-title>
                        <v-card-text>المصداقية والنزاهة في تقديم خدماتنا، من خلال دراسة الصعوبات التي تواجه العملاء للسعي في توفير أفضل الحلول المناسبة وبأفضل الأسعار على الأطلاق. نحن نؤمن بأننا جميعاً شركاء نجاح واحد قائم على الجودة والمصدقية في التعامل</v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-card height="300" class="text-center pa-5">
                        <v-icon color="purple" large>mdi-eye</v-icon>
                        <v-card-title><p class="mx-auto my-5">رؤيتنا</p></v-card-title>
                        <v-card-text> نيل رضا العميل من خلال المساعدة في توفير الحل الذكي الأمثل المناسب لتغطية احتياج العملاء وتطوير عملية تقديم المعلومة في كافة المجالات.</v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <!-- <p v-scrollanimation class="text-h6 text-md-h4 font-weight-thin text-right">- قيمنا</p>
            <p v-scrollanimation class="subtitle gray--text text-right">المصداقية والنزاهة في تقديم خدماتنا، من خلال دراسة الصعوبات التي تواجه العملاء للسعي في توفير أفضل الحلول المناسبة وبأفضل الأسعار على الأطلاق. نحن نؤمن بأننا جميعاً شركاء نجاح واحد قائم على الجودة والمصدقية في التعامل</p>
            <p v-scrollanimation class="text-h6 text-md-h4 font-weight-thin text-right">- رؤيتنا</p>
            <p v-scrollanimation class="subtitle gray--text text-right"> نيل رضا العميل من خلال المساعدة في توفير الحل الذكي الأمثل المناسب لتغطية احتياج العملاء وتطوير عملية تقديم المعلومة في كافة المجالات.</p> -->
            <v-card>
                <v-tabs
                v-model="tab"
                background-color="purple"
                centered
                dark
                icons-and-text
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab class="text-h6" href="#tab-1">
                        عن الشركة
                    </v-tab>

                    <v-tab class="text-h6" href="#tab-2">
                        سياسة الشركة
                    </v-tab>

                    <v-tab class="text-h6" href="#tab-3">
                        عملائنا
                    </v-tab>

                    <v-tab class="text-h6" href="#tab-4">
                        لماذا H-Technology
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-img max-width="50%" class="mx-auto my-5" src="../assets/2.jpg"></v-img>
                            <v-card-text class="text-center tab-text pa-5">رضا العميل هو الغاية الأولى من شركةH-Technology  ، فهي تهتم بمساعدة من يبحثون عن الوسائل الحديثة لتطوير أعمالهم وتقديمها بطريقة ذكية، وذلك من خلال توفير حلول وأدوات ذكية تساهم في تسهيل عرض الأفكار وتبسيط عملية متابعة الأعمال بأعلى جودة ممكنة وبالتعاون مع كبرى الشركات العالمية لتصنيع أدوات تطوير التعليم.</v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat>
                            <v-img max-width="50%" class="mx-auto my-5" src="../assets/z.jpg"></v-img>
                            <v-card-text class="tab-text pa-5">
                                <ul style="list-style-type: circle;">
                                    <li> تقديم استشارات تكنولوجيه لمساعدة الشركات في اختيار الحلول الذكية المناسبة لاحتياجاتهم، لضمان توفير خدمة متميزة وليس فقط الاهتمام بعملية البيع الاعتيادية</li>
                                    <li>خدمات ما بعد البيع للتأكد من جودة المنتج ومدى فعاليته في حل المشاكل وتوفير الحل الذكي المناسب للعميل.</li>
                                    <li> تقديم أفضل جودة بأفضل سعر.</li>
                                    <li> تقديم استشارات فنية ومتابعة مسئولي الـ IT بشكل مستمر طوال ساعات العمل لتقديم المساعدة دائماً في حل اي مشكلة تقنية أو عطل سريع.</li>
                                    <li> ضمان رضا العميل عن الخدمات المطروحة والمقدمة من الشركة والتأكد من مدى فعاليتها في حل مشكلة العميل</li>
                                    <li>نيل رضا العميل من خلال المساعدة في توفير الحل الذكي الأمثل المناسب لتغطية احتياج العملاء وتطوير عملية تقديم المعلومة في كافة المجالات.</li>
                                    <li> المصداقية والنزاهة في تقديم خدماتنا، من خلال دراسة الصعوبات التي تواجه العملاء للسعي في توفير أفضل الحلول المناسبة وبأفضل الأسعار على الأطلاق.</li>
                                    <li> نحن نؤمن بأننا جميعاً شركاء نجاح واحد قائم على الجودة والمصداقية في التعامل.</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                        <v-card flat>
                            <v-img max-width="50%" class="mx-auto my-5" src="../assets/x.png"></v-img>
                            <v-card-text class="tab-text pa-5">
                                <ul style="list-style-type: circle;">
                                    <li>المعلمين الساعين نحو تقديم المادة العلمية بأفضل وأذكى الطرق العلمية.</li>
                                    <li>مديري المدارس الساعين لتسهيل عملية التعليم باستخدام الحلول الذكية الحديثة</li>
                                    <li>أصحاب الشركات الراغبين في استخدام أدوات ذكية لتسهيل سير الأعمال داخل الشركة وتسهل التواصل والفاعلية بين الموظفين وكل افرادالشركة .</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-4">
                        <v-card flat>
                            <v-img max-width="50%" class="mx-auto my-5" src="../assets/x.jpg"></v-img>
                            <v-card-text class="text-center tab-text pa-5">
                                لأن رضاءك هو ما يميزنا  وهو مسؤليتنا ، فنحن نتيح لك بدائل وحلول ذكية لا حصر لها لمساعدتك في عرض أفكارك وتقديم معلوماتك بأفضل وأسهل طريقة ممكنة، وذلك من خلال تقديم مجموعة واسعة من المنتجات التكنولوجية والحلول الذكية المتطورة بأفضل جودة وأفضل سعر على الاطلاق.
نحن لا نهتم فقط بعملية البيع لكننا نسعى دائماً لمساعدة العميل في اختيار الحل الأمثل لاحتياجات البيزنس الخاص به، بالاضافة الي خدمة ما بعد البيع المتميزة لضمان جودة المنتجات ومدى فاعليه تأثيرها في حل مشكلة العميل.
ارضاء العميل هي غايتنا الأولى.
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
            <!-- <div v-scrollanimation class="tabs-cont">
                <div @click="activeTab = 1" :class="activeTab === 1 ? 'tab-btn active-tab' : 'tab-btn'">
                    <p class="body-1">عن الشركة</p>
                </div>
                <div @click="activeTab = 2" :class="activeTab === 2 ? 'tab-btn active-tab' : 'tab-btn'">
                    <p class="body-1"> سياسة الشركة</p>
                </div>
                <div @click="activeTab = 3" :class="activeTab === 3 ? 'tab-btn active-tab' : 'tab-btn'">
                    <p class="body-1">عملائنا</p>
                </div>
                <div @click="activeTab = 4" :class="activeTab === 4 ? 'tab-btn active-tab' : 'tab-btn'">
                    <p class="body-1">لماذا H-Technology</p>
                </div>
            </div>
            <v-row v-scrollanimation :align="$vuetify.breakpoint.smAndUp ? 'center' : ''" v-show="activeTab === 1">
                <v-col cols="12" sm="6">
                    <p class="gray--text darken-2">رضا العميل هو الغاية الأولى من شركةH-Technology  ، فهي تهتم بمساعدة من يبحثون عن الوسائل الحديثة لتطوير أعمالهم وتقديمها بطريقة ذكية، وذلك من خلال توفير حلول وأدوات ذكية تساهم في تسهيل عرض الأفكار وتبسيط عملية متابعة الأعمال بأعلى جودة ممكنة وبالتعاون مع كبرى الشركات العالمية لتصنيع أدوات تطوير التعليم.</p>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-img max-width="300" class="mx-auto" src="../assets/2.jpg"></v-img>
                </v-col>
            </v-row>
            <v-row v-scrollanimation :align="$vuetify.breakpoint.smAndUp ? 'center' : ''" v-show="activeTab === 2">
                <v-col cols="12" sm="6">
                    <v-img src="../assets/z.jpg"></v-img>
                </v-col>
                <v-col cols="12" sm="6">
                    <ul class="gray--text lighten-1">
                       <li> تقديم استشارات تكنولوجيه لمساعدة الشركات في اختيار الحلول الذكية المناسبة لاحتياجاتهم، لضمان توفير خدمة متميزة وليس فقط الاهتمام بعملية البيع الاعتيادية</li>
                       <li>خدمات ما بعد البيع للتأكد من جودة المنتج ومدى فعاليته في حل المشاكل وتوفير الحل الذكي المناسب للعميل.</li>
                       <li> تقديم أفضل جودة بأفضل سعر.</li>
                       <li> تقديم استشارات فنية ومتابعة مسئولي الـ IT بشكل مستمر طوال ساعات العمل لتقديم المساعدة دائماً في حل اي مشكلة تقنية أو عطل سريع.</li>
                       <li> ضمان رضا العميل عن الخدمات المطروحة والمقدمة من الشركة والتأكد من مدى فعاليتها في حل مشكلة العميل</li>
                      <li>نيل رضا العميل من خلال المساعدة في توفير الحل الذكي الأمثل المناسب لتغطية احتياج العملاء وتطوير عملية تقديم المعلومة في كافة المجالات.</li>
                      <li> المصداقية والنزاهة في تقديم خدماتنا، من خلال دراسة الصعوبات التي تواجه العملاء للسعي في توفير أفضل الحلول المناسبة وبأفضل الأسعار على الأطلاق.</li>
                      <li> نحن نؤمن بأننا جميعاً شركاء نجاح واحد قائم على الجودة والمصداقية في التعامل.</li>
                    </ul>
                </v-col>
            </v-row>
            <v-row v-scrollanimation :align="$vuetify.breakpoint.smAndUp ? 'center' : ''" v-show="activeTab === 3">
                <v-col cols="12" sm="6">
                    <ul class="gray--text lighten-1">
                        <li>المعلمين الساعين نحو تقديم المادة العلمية بأفضل وأذكى الطرق العلمية.</li>
                        <li>مديري المدارس الساعين لتسهيل عملية التعليم باستخدام الحلول الذكية الحديثة</li>
                        <li>أصحاب الشركات الراغبين في استخدام أدوات ذكية لتسهيل سير الأعمال داخل الشركة وتسهل التواصل والفاعلية بين الموظفين وكل افرادالشركة .</li>
                    </ul>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-img src="../assets/x.png"></v-img>
                </v-col>
            </v-row>
            <v-row v-scrollanimation :align="$vuetify.breakpoint.smAndUp ? 'center' : ''" v-show="activeTab === 4">
                <v-col cols="12" sm="6">
                    <p class="gray--text darken-2">لأن رضاءك هو ما يميزنا  وهو مسؤليتنا ، فنحن نتيح لك بدائل وحلول ذكية لا حصر لها لمساعدتك في عرض أفكارك وتقديم معلوماتك بأفضل وأسهل طريقة ممكنة، وذلك من خلال تقديم مجموعة واسعة من المنتجات التكنولوجية والحلول الذكية المتطورة بأفضل جودة وأفضل سعر على الاطلاق.
نحن لا نهتم فقط بعملية البيع لكننا نسعى دائماً لمساعدة العميل في اختيار الحل الأمثل لاحتياجات البيزنس الخاص به، بالاضافة الي خدمة ما بعد البيع المتميزة لضمان جودة المنتجات ومدى فاعليه تأثيرها في حل مشكلة العميل.
ارضاء العميل هي غايتنا الأولى.</p>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-img width="90%" src="../assets/fp.png"></v-img>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script>
import slider from '../components/sliders.vue'

export default {
  components: {
    slider
  },
  data () {
    return {
      activeTab: 1,
      tab: null
    }
  }
}
</script>

<style scoped>
    .tab-text {
        color: #111;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
    }
    .tabs-cont {
        margin: 50px auto;
        display: flex;
        text-align: center;
        border: thin solid #a9a9a9;
        border-radius: 5px;
        width: fit-content;
        height: 45px;
        overflow: hidden;
    }
    .tab-btn {
        top: 50%;
        height: 50px;
        font-size: 1rem;
        font-weight: thin;
        text-align: center;
        width: fit-content;
        color: #34383D;
        cursor: pointer;
        padding: 0 20px;
    }
    .tab-btn:nth-of-type(1) {
        border-right: thin solid #a9a9a9;
    }
    .tab-btn:nth-of-type(3) {
        border-left: thin solid #a9a9a9;
    }
    .tab-btn p {
        transform: translateY(9px);
        letter-spacing: 2px;
        word-break: keep-all;
    }
    .active-tab {
        background-color: #6DCDE5;
        color: white;
        border: none;
        width: fit-content;
    }
    .before-enter {
        opacity: 0;
        transform: translateX(0px);
        transition: all 1s ease-out;
    }
    .enter {
        opacity: 1;
        transform: translateX(0px);
    }
    @media screen and (max-width: 670px) {
        .tabs-cont {
            /* margin-bottom: 100px; */
            display: block;
            height: auto !important;
            width: 100%;
        }
        .tab-btn {
            width: auto;
        }
        .tab-btn:nth-of-type(1) {
            border-right: none;
            border-bottom: thin solid #a9a9a9;
        }
        .tab-btn:nth-of-type(3) {
            border-left: none;
            border-top: thin solid #a9a9a9;
        }
    }
</style>
