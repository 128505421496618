import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    catImg: ''
  },
  mutations: {
    setCatImg (state, payload) {
      state.catImg = payload
    }
  },
  actions: {
    setCatImg (context, payload) {
      context.commit('setCatImg', payload)
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
