<template>
    <div>
        <v-snackbar top v-model="snackbar" :color="color" :timeout="5000">
            <v-row justify="space-between">
                <p class="mb-0 mt-1">{{text}}</p>
                <v-btn icon color="white" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
        <v-carousel cycle show-arrows-on-hover hide-delimiters>
            <template v-slot:prev="{ on, attrs }">
                <v-btn
                fab
                outlined
                v-bind="attrs"
                v-on="on"
                >
                    <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
                <v-btn
                fab
                outlined
                color=""
                v-bind="attrs"
                v-on="on"
                >
                    <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
            </template>
            <v-carousel-item v-for="category in categories" :key="category.id">
                <v-img
                :aspect-ratio="16/9"
                :src="category.photoPath"
                gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
                width="100%"
                >
                    <v-container>
                        <v-row align="center" justify="center" style="height: 500px;">
                            <v-col cols="12" sm="6" class="slide-in-left text-center">
                                <p class="text-h2 white--text">{{category.categoryName}}</p>
                                <p class="text-center subheader-text white--text">تصفح العديد من المنتجات </p>
                                <v-btn
                                large
                                outlined
                                @click="saveCatImg(category.photoPath); $router.push({ name: 'products', params: { catId: category.id, catName: category.categoryName } })"
                                class="mt-5 py-5 px-10 elevation-0" color="white white--text"
                                >
                                    <span class="body-1 font-weight-bold">عرض المنتجات</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-img>
            </v-carousel-item>
        </v-carousel>
        <v-progress-linear
        v-if="pageLoading && categories.length === 0"
        color="deep-purple accent-4"
        indeterminate
        height="6"
        ></v-progress-linear>
        <v-container v-if="!pageLoading && categories.length > 0" class="my-15">
            <v-row v-scrollanimation align="center">
                <v-col cols="12">
                    <div>
                        <p class="darkPurple--text headTitle">كل الفئات</p>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-scrollanimation cols="12" sm="6" md="4" lg="3" v-for="category in categories" :key="category.id">
                    <v-tooltip top color="purple">
                        <template v-slot:activator="{ on, attrs }">
                            <v-card
                            v-bind="attrs"
                            v-on="on"
                            class="mx-auto my-5 elevation-4"
                            color="purple"
                            tile
                            @click="saveCatImg(category.photoPath); $router.push({ name: 'products', params: { catId: category.id, catName: category.categoryName } })"
                            >
                                <v-hover close-delay="25" v-slot="{ hover }">
                                    <div class="img-cont">
                                        <img :class="hover ? 'img-hover' : 'img'" :src="category.photoPath" width="100%">
                                    </div>
                                </v-hover>
                                <v-card-text
                                class="pt-6"
                                style="position: relative;"
                                >
                                    <h3 class="text-h5 white--text mb-2">
                                        {{category.categoryName}}
                                    </h3>
                                    <v-hover open-delay="15" close-delay="15" v-slot="{ hover }">
                                        <div :class="hover ? 'font-weight-light desc title white purple--text mb-2' : 'font-weight-medium desc title white--text mb-2'">
                                        عرض المنتجات
                                        <v-icon :color="hover? 'purple' : 'white'">mdi-chevron-left</v-icon>
                                        </div>
                                    </v-hover>
                                </v-card-text>
                            </v-card>
                        </template>
                        <span>{{category.categoryName}}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-container>
        <p v-if="!pageLoading && categories.length === 0" class="text-center mt-5">لم نتمكن من الوصول للخادم</p>
    </div>
</template>

<script>
import { categories } from '../links'
export default {
  data () {
    return {
      categories: [],
      pageLoading: true,
      snackbar: false,
      text: '',
      color: ''
    }
  },
  methods: {
    saveCatImg (img) {
      this.$store.dispatch('setCatImg', img)
    }
  },
  created () {
    this.$http.get(categories, { timeout: 15000 }).then(response => {
      this.pageLoading = false
      if (response.status === 200) {
        this.categories = response.body
      } else {
        this.snackbar = true
        this.color = 'error'
        this.text = response.statusText
      }
    }, () => {
      this.snackbar = true
      this.color = 'error'
      this.text = 'Something went wrong'
      this.pageLoading = false
    })
  }
}
</script>

<style>
    .img-cont {
        height: 150px;
        width: auto;
        overflow: hidden;
    }
    .img {
        transition: all 0.5s ease-in-out;
        transform: scale(1);
    }
    .img-hover {
        transition: all 0.5s ease-in-out;
        transform: scale(1.2);
    }
    .headTitle {
        box-sizing: border-box;
        border-right: 2px solid #393783;
        padding-right: 10px;
        font-size: 52px;
        font-weight: 600;
    }
    .desc {
        animation: fade 1s ease-in-out;
    }
    @keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @media screen and (max-width: 600px) {
        .headTitle {
            border-right: none;
        }
    }
</style>
