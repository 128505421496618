<template>
    <div class="nav">
        <v-app-bar
        app
        color="white"
        light
        elevation="1"
        height="75"
        class="pb-0"
        >
            <v-container>
                <v-row align="end">
                    <v-app-bar-nav-icon @click="drawer = true" x-large left absolute class="d-flex d-md-none mt-2" color="purple"></v-app-bar-nav-icon>
                    <img @click="goTo('Home')" class="logo" src="../assets/logo-t.png">
                    <v-spacer></v-spacer>
                    <template>
                        <div class="d-none d-md-flex">
                            <div :class="$route.name === 'Home' ? 'nav-link active-link' : 'nav-link'" @click="goTo('Home')">
                                <p class="text-h6 font-weight-light mb-0">الرئيسية</p>
                            </div>
                            <v-menu class="elevation-4" open-on-hover nudge-bottom="75" transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" :class="$route.name === 'categories' || $route.name === 'products' ? 'nav-link active-link' : 'nav-link'" @click="goTo('categories')">
                                        <p class="text-h6 font-weight-light mb-0">المنتجات</p>
                                    </div>
                                </template>
                                <v-list tile style="direction: ltr;" dense nav>
                                    <v-list-item-group
                                    active-class="lightBlue--text"
                                    >
                                        <v-list-item v-for="item in categories" @click="saveCatImg(item.photoPath)" :key="item.id" link :to="{name: 'products', params: {catId: item.id, catName: item.categoryName}}">
                                            <v-list-item-title class="body-1">{{item.categoryName}}</v-list-item-title>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                            <div :class="$route.name === 'about' ? 'nav-link active-link' : 'nav-link'" @click="goTo('about')">
                                <p class="text-h6 font-weight-light mb-0">تعرف علينا</p>
                            </div>
                            <div :class="$route.name === 'contact' ? 'nav-link active-link' : 'nav-link'" @click="goTo('contact')">
                                <p class="text-h6 font-weight-light mb-0">تواصل معنا</p>
                            </div>
                        </div>
                    </template>
                </v-row>
            </v-container>
        </v-app-bar>
        <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        color="purple"
        dark
        dir="rtl"
        right
        >
            <v-list
            class="mt-10"
            nav
            >
                <v-list-item class="mb-5" link to="/">
                    <v-list-item-title class="body-1 text-capitalize">الرئيسية</v-list-item-title>
                </v-list-item>
                <v-list-group active-class="white--text" class="mb-5">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="white--text body-1 text-capitalize">المنتجات</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item active-class="white--text" link to="/categories">
                        <v-list-item-title class="body-1 text-capitalize ml-3">كل الفئات</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="item in categories" :key="item.id" link @click="saveCatImg(item.photoPath)" :to="{name: 'products', params: {catId: item.id, catName: item.categoryName}}">
                        <v-list-item-title class="body-1">{{item.categoryName}}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item class="mb-5" link to="/about">
                    <v-list-item-title class="body-1 text-capitalize">تعرف علينا</v-list-item-title>
                </v-list-item>
                <v-list-item class="mb-5" link to="/contact">
                    <v-list-item-title class="body-1 text-capitalize">تواصل معنا</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { categories } from '../links'

export default {
  data () {
    return {
      links: [
        { text: 'Home', route: 'Home' },
        { text: 'Categoris', route: 'categoris' },
        { text: 'About', route: 'about' },
        { text: 'Contact', route: 'contact' }
      ],
      drawer: false,
      categories: []
    }
  },
  methods: {
    saveCatImg (img) {
      this.$store.dispatch('setCatImg', img)
    },
    goTo (link) {
      if (this.$route.name !== link) this.$router.push({ name: link })
    }
  },
  created () {
    this.$http.get(categories, { timeout: 15000 }).then(response => {
      if (response.status === 200) {
        this.categories = response.body
      }
    })
  }
}
</script>

<style>
    .scale-up-hor-right {
        animation: scale-up-hor-right 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
    .scale-up-top {
        position: fixed;
        top: 60px;
        left: 12px;
        z-index: 1;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        animation: scale-up-top 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
    .logo {
        cursor: pointer;
        margin-bottom: 5px;
        max-width: 80px;
        max-height: 70px;
    }
    .nav {
        font-family: 'Lato', sans-serif;
    }
    .nav-link {
        height: 75px;
        margin-left: 30px;
        min-width: 50px;
        color: #34383D;
        text-align: center;
        padding: 0 10px;
        transition: all 0.3s ease;
        cursor: pointer;
    }
    .active-link {
       border-bottom: 2px #4E4CA0 solid;
       color: #4E4CA0 !important;
    }
    .nav-link p {
        transform: translateY(50%);
    }
    .nav-link:hover {
        border-bottom: 2px #4E4CA0 solid;
        color: #4E4CA0;
    }
    .text-search {
        max-height: 40px !important;
    }
    .v-menu__content {
        box-shadow: 0px 1px 2px rgb(218, 218, 218) !important;
        border-radius: 0px !important;
    }
    @keyframes scale-up-hor-right {
        0% {
            transform: scaleX(0.4);
            transform-origin: 100% 100%;
        }
        100% {
            transform: scaleX(1);
            transform-origin: 100% 100%;
        }
    }
    @keyframes scale-up-top {
        0% {
            transform: scale(0.5);
            transform-origin: 50% 0%;
        }
        100% {
            transform: scale(1);
            transform-origin: 50% 0%;
        }
    }
    @media screen and (max-width: 960px) {
        .logo {
            max-height: 60px;
            margin: auto;
        }
    }
</style>
