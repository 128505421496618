var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('slider'),_c('v-container',{staticClass:"my-15"},[_c('p',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"text-center header-text"},[_vm._v("كيف نعمل؟")]),_c('v-row',{staticClass:"my-10",attrs:{"justify":"center"}},[_c('v-col',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","outlined":!hover,"color":"purple","small":""}},[_c('span',{class:hover ? 'white--text body-1' : 'purple--text body-1'},[_vm._v("١")])])]}}])}),_c('div',{staticClass:"mr-5"},[_c('p',{staticClass:"body-text gray--text"},[_vm._v(" استقبال طلب العميل ودراسته جيداً لتحديد الاحتياج والحل المناسب له.")])])],1)]),_c('v-col',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","outlined":!hover,"color":"purple","small":""}},[_c('span',{class:hover ? 'white--text body-1' : 'purple--text body-1'},[_vm._v("٢")])])]}}])}),_c('div',{staticClass:"mr-5"},[_c('p',{staticClass:"body-text gray--text"},[_vm._v(" مناقشة كافة الحلول الممكنة مع العميل لتحديد الحل الأنسب و تقديم كافة الحلول المناسبة بأفضل جودة وأفضل سعر")])])],1)]),_c('v-col',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","outlined":!hover,"color":"purple","small":""}},[_c('span',{class:hover ? 'white--text body-1' : 'purple--text body-1'},[_vm._v("٣")])])]}}])}),_c('div',{staticClass:"mr-5"},[_c('p',{staticClass:"body-text gray--text"},[_vm._v(" تنفيذ الحل المناسب المُتفق عليه بيننا وبين العميل وضمان جودة التركيب والتنفيذ بأفضل سعر وأقل وقت ممكن.")])])],1)]),_c('v-col',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","outlined":!hover,"color":"purple","small":""}},[_c('span',{class:hover ? 'white--text body-1' : 'purple--text body-1'},[_vm._v("٤")])])]}}])}),_c('div',{staticClass:"mr-5"},[_c('p',{staticClass:"body-text gray--text"},[_vm._v(" المتابعة الدورية مع العميل لضمان سلامة المنتج والمساعدة في حل اي عطل سريع أو مشكلة فنية سريعة.")])])],1)])],1),_c('v-divider'),_c('contact',{staticClass:"mt-15"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }