var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":"","color":_vm.color,"timeout":5000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('p',{staticClass:"mb-0 mt-1"},[_vm._v(_vm._s(_vm.text))]),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"cover",style:(("background-image: url('" + (_vm.$store.state.catImg) + "')")),attrs:{"id":"cover"}},[_c('div',{staticClass:"cover-header"},[_c('p',{staticClass:"text-center cover-header-text"},[_vm._v("Check out our "+_vm._s(_vm.$route.params.catName)+" products")])])]),(_vm.pageLoading && _vm.products.length === 0)?_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","height":"6"}}):_vm._e(),(!_vm.pageLoading && _vm.products.length > 0)?_c('v-container',[_c('v-row',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"my-10",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('p',{staticClass:"darkPurple--text headTitle text-capetalize"},[_vm._v(_vm._s(_vm.$route.params.catName)+" بأفضل سعر")])])])],1),_c('v-row',{staticClass:"my-10"},_vm._l((_vm.products),function(product){return _c('v-col',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],key:product.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-tooltip',{attrs:{"top":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mx-auto my-5 elevation-4",attrs:{"color":"purple","tile":"","min-height":"320"},on:{"click":function($event){_vm.$router.push({ name: 'details' }); _vm.setProduct(product)}}},'v-card',attrs,false),on),[(product.specialOffer)?_c('img',{staticClass:"ribbon-img",attrs:{"src":require("../assets/ribbon.png"),"alt":"ribbon"}}):_vm._e(),_c('v-hover',{attrs:{"close-delay":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"img-cont"},[_c('img',{class:hover ? 'img-hover' : 'img',attrs:{"src":product.photoPath,"width":"100%"}})])]}}],null,true)}),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('h3',{staticClass:"text-h5 white--text mb-2"},[_vm._v(" "+_vm._s(product.name)+" ")]),(product.price && product.price > 0)?_c('div',{staticClass:"font-weight-bold desc title white--text"},[_vm._v("EGP"+_vm._s(product.price))]):_vm._e()]),_c('v-hover',{attrs:{"close-delay":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card-actions',[_c('div',{class:hover ? 'font-weight-light desc title white purple--text mb-2' : 'font-weight-medium desc title white--text mb-2'},[_vm._v(" اعرض التفاصيل "),_c('v-icon',{attrs:{"color":hover? 'purple' : 'white'}},[_vm._v("mdi-chevron-left")])],1)])]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(product.name))])])],1)}),1)],1):_vm._e(),(!_vm.pageLoading && _vm.products.length === 0)?_c('p',{staticClass:"text-center mt-5"},[_vm._v("لم نتمكن من الوصول للخادم")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }