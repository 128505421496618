import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  theme: {
    themes: {
      light: {
        blackish: '#34383D',
        lightBlue: '#6DCDE5',
        purple: '#2a5298',
        darkPurple: '#393783'
      }
    }
  }
})
