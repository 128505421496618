<template>
    <div>
        <v-snackbar top v-model="snackbar" :color="color" :timeout="5000">
          <v-row justify="space-between">
            <p class="mb-0 mt-1">{{text}}</p>
            <v-btn icon color="white" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <!-- <slider/> -->
        <div class="cover" id="cover">
          <div class="cover-header">
            <p class="text-center cover-header-text">{{product.name}}</p>
          </div>
        </div>
        <v-progress-linear
        v-if="pageLoading && !product"
        color="deep-purple accent-4"
        indeterminate
        height="6"
        ></v-progress-linear>
        <v-container v-if="!pageLoading && product">
            <v-img max-width="50%" class="mx-auto" :src="product.photoPath"></v-img>
            <p class="text-h4 font-weight-black text-center my-5 darkPurple--text text-capetalize">{{product.name}}</p>
            <!-- <p class="text-h5 font-weight-black my-5 darkPurple--text text-capetalize">وصف المنتج</p> -->
            <p v-if="product.generalDescription" class="body-1 text-center">{{product.generalDescription}}</p>
            <div class="body-1" v-html="product.description"></div>
            <v-divider v-if="product.price > 0 && product.price" class="my-5"></v-divider>
            <v-row class="my-5 px-5" v-if="product.price > 0 && product.price" justify="space-between">
              <p class="text-h5 font-weight-black darkPurple--text">السعر </p>
              <p class="text-h5 font-weight-black text-capetalize">{{product.price}} جم</p>
            </v-row>
            <v-row justify="center">
                <v-btn large class="mx-auto my-10" @click="$router.push({name: 'contact'})" color="purple" dark>تواصل للشراء</v-btn>
            </v-row>
        </v-container>
        <p v-if="!pageLoading && !product" class="text-center mt-5">لم نتمكن من الوصول للخادم</p>
    </div>
</template>

<script>
// import slider from '../components/sliders'

export default {
  // components: {
  //   slider
  // },
  data () {
    return {
      product: null,
      pageLoading: true,
      snackbar: false,
      text: '',
      color: ''
    }
  },
  mounted () {
    document.getElementById('cover').style.backgroundImage = `url('${this.product.photoPath}')`
  },
  created () {
    if (localStorage.getItem('product')) {
      this.product = JSON.parse(localStorage.getItem('product'))
      this.pageLoading = false
    } else {
      this.pageLoading = false
    }
  }
}
</script>
