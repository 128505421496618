<template>
  <v-app  class="app">
    <navbar></navbar>
    <v-main class="main-cont">
      <router-view></router-view>
    </v-main>
    <footerBox></footerBox>
  </v-app>
</template>

<script>
import navbar from './components/navbar.vue'
import footerBox from './components/footer.vue'

export default {
  name: 'App',
  components: {
    navbar,
    footerBox
  },
  data () {
    return {}
  }
}
</script>

<style>
  .v-application {
    font-family: 'Tajawal', sans-serif;
  }
  .before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-out;
  }
  .enter {
    opacity: 1;
    transform: translateY(0px);
  }
  .header-text {
    font-size: 52px;
    font-weight: 600;
    color: #333;
  }
  .body-text {
    font-size: 18px;
    color: #333;
  }
</style>
